import React from "react";
import ChildProtectionPolicy from "../../sections/child-protection-policy/ChildProtectionPolicy";

const ChildProtectionPolicyPage = () => {
    return (
        <div>
            <ChildProtectionPolicy />
        </div>
    );
};

export default ChildProtectionPolicyPage;
