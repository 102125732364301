import { Navigate } from "react-router-dom";
import Home from "./pages/HomePage/HomePage";
import { useRoutes } from "react-router-dom";
import FaqPage from "./pages/FaqPage/FaqPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import NotFoundPage from "./pages/404Page/NotFoundPage";
import SupportPage from "./pages/SupportPage/SupportPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsNCondition from "./pages/TermsNCondition/TermsNCondition";
import DeleteAccountPage from "./pages/DeleteAccountPage/DeleteAccountPage";
import ChildProtectionPolicyPage from "./pages/child-protection-policy/ChildProtectionPolicyPage";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/faq",
      element: <FaqPage />,
    },
    {
      path: "/support",
      element: <SupportPage />,
    },
    {
      path: "/delete-account",
      element: <DeleteAccountPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/child-protection-and-safety-policy",
      element: <ChildProtectionPolicyPage />,
    },
    {
      path: "/terms-&-conditions",
      element: <TermsNCondition />,
    },
    {
      path: "/404",
      element: <NotFoundPage />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
  return routes;
}
