import React from "react";

// Files Import
import StyledMdText from "../../common/components/StyledMdText/StyledMdText";
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";
import StyledH6Heading from "../../common/components/StyledH6Heading/StyledH6Heading";
import { Link } from "react-router-dom";

const ChildProtectionPolicy = () => {
    return (
        <div className="py-10 max-w-[1440px] mx-auto">
            <div className="flex pt-8 w-11/12 md:w-3/4 lg:w-[62%] mx-auto">
                <img src="/assets/policy-pets.png" alt="Policy Pets" />
            </div>
            <div className="py-4 text-center md:py-5">
                <StyledH2Heading fontColor={"text-black"} content={"Child Protection & Safety Policy"} />
            </div>

            <div className="bg-lightGrey space-y-5 rounded-[18px] py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
                {/* Introduction */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"1. Introduction"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"Paw Play Love is committed to ensuring the safety and well-being of all users, including the prevention of child sexual abuse and exploitation (CSAE) and child sexual abuse material (CSAM). This policy outlines our zero-tolerance approach to any form of child exploitation and the measures we take to prevent, detect, and report such activities."} />
                </div>

                {/* Zero-Tolerance Policy */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"2. Zero-Tolerance Policy"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"Paw Play Love strictly prohibits any content, activity, or communication that involves CSAE or CSAM, including but not limited to:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li>The creation, distribution, or possession of CSAM.</li>
                        <li>Any attempt to exploit, groom, or solicit minors.</li>
                        <li>The use of the platform to facilitate or promote child trafficking or abuse.</li>
                    </ul>
                    <StyledMdText fontColor={"text-darkGrey"} content={'Any violation of this policy will result in an immediate and permanent ban, along with potential legal action and reporting to law enforcement agencies.'} />

                </div>

                {/* Age Verification & Compliance */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"3. Age Verification & Compliance"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"To protect minors and ensure compliance with laws, Paw Play Love implements strict age verification measures, including:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li>Requiring users to confirm they are at least <b>18 years old</b> to create an account.</li>
                        <li>Using AI and manual reviews to verify profiles and detect inconsistencies.</li>
                        <li>Regularly auditing user-reported age discrepancies.</li>
                    </ul>
                </div>

                {/* Content Moderation & Detection */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"4. Content Moderation & Detection"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"To identify and remove harmful content, Paw Play Love employs:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li>Automated scanning and filtering tools to detect prohibited content.</li>
                        <li>Human moderators trained in identifying CSAE and CSAM.</li>
                        <li>User reporting mechanisms that allow swift action on flagged accounts or content.</li>
                    </ul>
                </div>

                {/* Reporting & Cooperation */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"5. Reporting & Cooperation with Law Enforcement"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"Paw Play Love actively cooperates with law enforcement agencies and child protection organizations, including:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li>Immediate reporting of CSAE and CSAM-related content to the <b>National Center for Missing & Exploited Children (NCMEC)</b> and relevant authorities.</li>
                        <li>Providing necessary information to assist in investigations.</li>
                        <li>Maintaining detailed records of reports, flagged content, and actions taken.</li>
                    </ul>
                </div>

                {/* User Education & Awareness */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"6. User Education & Awareness"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"We believe in proactive education to prevent exploitation. Paw Play Love provides:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li>Resources and guidelines for recognizing and reporting suspicious behavior.</li>
                        <li>Safety tips for users on how to interact securely on the platform.</li>
                        <li>Regular updates on our safety policies and enforcement measures.</li>
                    </ul>
                </div>

                {/* Enforcement & Accountability */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"7. Enforcement & Accountability"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={"Users found in violation of this policy will face strict consequences, including:"} />
                    <ul className="pl-5 list-disc text-darkGrey">
                        <li><b>Immediate suspension or permanent ban</b> from the platform.</li>
                        <li><b>Legal action and referral</b > to law enforcement when applicable</li>
                        <li><b>Ongoing monitoring</b> to prevent repeat offenses and protect the community.</li>
                    </ul>
                </div>

                {/* Policy Updates & Compliance */}
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledH6Heading fontColor={"text-black"} content={"8. Policy Updates & Compliance"} />
                    <StyledMdText fontColor={"text-darkGrey"} content={
                        <span dangerouslySetInnerHTML={{
                            __html: "This policy is reviewed and updated regularly to comply with global child protection laws, including the <b>Children’s Online Privacy Protection Act (COPPA)</b> and international standards."
                        }} />
                    } />
                    <StyledMdText
                        fontColor={"text-darkGrey"}
                        content={
                            <>
                                For concerns or to report violations, contact our <b>Safety & Compliance Team</b> at{" "}
                                <Link
                                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                                    to="mailto:info@pawplaylove.com"
                                >
                                    info@pawplaylove.com
                                </Link>.
                            </>
                        }
                    />

                </div>
                <hr className="text-darkGrey" />
                <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2">
                    <StyledMdText fontColor={"text-darkGrey"} content={"Paw Play Love is dedicated to creating a safe and responsible community. We take all reports seriously and work diligently to ensure that our platform is free from exploitation and harm."} />
                </div>
            </div>
        </div>
    );
};

export default ChildProtectionPolicy;
